import api from '@apiInstance';

export default {
  add (params) {
    return api.post('notification_templates', params);
  },
  get (id) {
    return api.fetch('notification_templates/' + id);
  },
  list (params) {
    return api.fetch('notification_templates', { params });
  },
  update (params) {
    return api.patch('notification_templates', params);
  },
  delete (id) {
    return api.remove('notification_templates', id);
  }
};
