<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Notification Templates"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadNotificationTemplates()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in fields"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModal"
      centered
      @hide="closeModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Module" label-for="v-text">
            <b-form-select
              @change="onSelectModule($event)"
              v-model="formData.code"
              :options="modules"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Trigger Point" label-for="v-text">
            <b-form-select
              v-model="formData.trigger_point"
              :options="triggers"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Notification Receiver" label-for="v-text">
            <b-form-select
              v-model="formData.notification_receiver"
              :options="receivers"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Notification Method" label-for="v-text">
            <b-form-select
              v-model="formData.notification_method"
              :options="methods"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-text">
            <b-form-textarea row="2" v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Title" label-for="v-text">
            <b-form-input v-model="formData.title" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Content" label-for="v-text">
            <quill-editor
              ref="quillEditorRef"
              v-model="content"
              id="v-description"
              :options="{ theme: 'snow' }"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import notifcationTemplatesApi from '@api/notification_templates';
import { queryParameters } from '@/schema';

export default {
  text: 'NotificationTemplatesPage',
  components: {},
  data: () => ({
    content: '',
    triggers: [],
    totalCount: 0,
    page: 0,
    size: 0,
    isShowingMap: false,
    showModal: false,
    dataSource: [],
    fields: [
      { caption: 'Module', field: 'code' },
      { caption: 'Title', field: 'title' },
      { caption: 'Description', field: 'description' },
      { caption: 'Method', field: 'notification_method' },
      { caption: 'Receiver', field: 'notification_receiver' },
    ],
    receivers: [
      { text: 'Admin', value: 'admin' },
      { text: 'Technician', value: 'tech' },
      { text: 'All Technician', value: 'techa' },
      { text: 'Customer', value: 'customer' },
      { text: 'User', value: 'user' },
    ],
    methods: [
      { text: 'Email', value: 'email' },
      { text: 'WhatsApp', value: 'whatsapp' },
      { text: 'Notification', value: 'notification' },
      { text: 'Timeline', value: 'timeline' },
    ],
    modules: [
      {
        text: 'Service Request',
        value: 'servicerequest',
        triggers: [
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
          {
            text: 'Created',
            value: 'created',
          },
          {
            text: 'Completed',
            value: 'completed',
          },
          {
            text: 'Completed with Follow Up',
            value: 'completedex',
          },
          {
            text: 'Accepted',
            value: 'accepted',
          },
          {
            text: 'Rejected',
            value: 'rejected',
          },
        ],
      },
      {
        text: 'Quotation',
        value: 'quotation',
        triggers: [
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
          {
            text: 'Created',
            value: 'created',
          },
        ],
      },
      {
        text: 'Sales Order',
        value: 'salesorder',
        triggers: [
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
          {
            text: 'Created',
            value: 'created',
          },
        ],
      },
      {
        text: 'Job Order',
        value: 'joborder',
        triggers: [
          {
            text: 'Job Status:New',
            value: 'new',
          },
          {
            text: 'Job Status:Work In Progress',
            value: 'wip',
          },
          {
            text: 'Job Status:Paused',
            value: 'paused',
          },
          {
            text: 'Job Status:Done',
            value: 'done',
          },
          {
            text: 'Job Status:Completed',
            value: 'report sent',
          },
          // {
          //   text: 'Job Status:Conflicted',
          //   value: 'conflicted',
          // },
          {
            text: 'Published',
            value: 'created',
          },
          {
            text: 'Assigned Specific Technician',
            value: 'assigned',
          },
          {
            text: 'Accepted',
            value: 'accepted',
          },
          {
            text: 'Declined',
            value: 'declined',
          },
          {
            text: 'Completed',
            value: 'completed',
          },
          {
            text: 'Service Report Submitted',
            value: 'submitted',
          },
          {
            text: 'Ungrab After 5 minutes',
            value: 'ungrab',
          },
          {
            text: 'Transfer',
            value: 'reassign',
          },
          {
            text: 'Email to CC',
            value: 'emailtocc',
          },
        ],
      },
      {
        text: 'Delivery Order',
        value: 'deliveryorder',
        triggers: [
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
          {
            text: 'Created',
            value: 'created',
          },
        ],
      },
      {
        text: 'Sales Invoice',
        value: 'salesinvoice',
        triggers: [
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
          {
            text: 'Sent',
            value: 'send',
          },
        ],
      },
      {
        text: 'Service Report',
        value: 'servicereport',
        triggers: [
          {
            text: 'Approved',
            value: 'approved',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
          {
            text: 'Created',
            value: 'created',
          },
        ],
      },
      {
        text: 'User',
        value: 'user',
        triggers: [
          {
            text: 'Forgot Password',
            value: 'forgotpassword',
          },
          {
            text: 'Register',
            value: 'register',
          },
          {
            text: 'Verified',
            value: 'verified',
          },
        ],
      },
    ],
    formData: {},
  }),
  watch: {
    page(v) {
      this.loadNotificationTemplates();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadNotificationTemplates();
      }
    },
  },
  mounted() {
    this.loadNotificationTemplates();
  },
  methods: {
    loadNotificationTemplates() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      notifcationTemplatesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;

      this.formData.content = this.content;

      if (this.formData.id) {
        app = notifcationTemplatesApi.update(this.formData);
      } else {
        app = notifcationTemplatesApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.loadNotificationTemplates();
            this.closeModal();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onOpenModal(event) {
      this.showModal = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        const { quill } = this.$refs.quillEditorRef;
        if (quill) {
          quill.root.innerHTML = data.content;
        }

        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      notifcationTemplatesApi
        .delete(event)
        .then(() => {
          this.loadNotificationTemplates();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSelectModule(v) {
      console.log(v);
      const { triggers } = this.modules.find((x) => {
        return x.value === v;
      });

      this.triggers = triggers;
    },
    closeModal() {
      this.showModal = false;
      this.content = '';

      this.$nextTick(() => {
        this.formData = {};
      });
    },
  },
};
</script>

<style></style>
